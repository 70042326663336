<template>
  <el-dialog title="我的优惠券" :visible.sync="visible" @close="closeDialog" class="table">
    <el-table :data="tableData" class="table">
      <el-table-column type="index" width="30"></el-table-column>
      <el-table-column label="优惠券" prop="name" class="font-bold"  ></el-table-column>
      <el-table-column label="金额" prop="typeMoney" class="font-bold" ></el-table-column>
      <el-table-column label="最低消费" prop="minAmount" class="font-bold" ></el-table-column>
      <el-table-column label="状态" prop="coupon_status" class="font-bold" :formatter="formatUseState"></el-table-column>
      <el-table-column label="过期时间" prop="useEndDate" class="font-red" :formatter="formatDate"></el-table-column>
      <el-table-column label="操作" prop="gotoUse" class="font-red" >
        <template slot-scope="scope">
          <span v-if="scope.row.coupon_status==1">
          <el-button type="text" @click="gotoUseQuan()">去使用</el-button>
            </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="tableData.length"
      v-if="tableData.length > 10"
    ></el-pagination>
  </el-dialog>
</template>

<script>
import {List} from '../../../api/coupon'
export default {
  props: ["dialogVisible"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val) this.getTableDatas();
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false,
      tableData: [],
      currentPage: 1,
      pageSize: 10
    };
  },
  methods: {
    getTableDatas() {
      List().then((result) => {
        if(result!=null){
          this.tableData = result;
        }
      }).catch((error) => {
        console.log(JSON.stringify(error));
      })
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    gotoUseQuan(){
      this.$router.push({ path: "/pay" });
    },

    formatUseState(row){
      return row.coupon_status==1?"可用":row.coupon_status==2?"已使用":"已过期";//1可用，2 已用，3过期
    },
    formatDate(row){//格式化时间
      var t=new Date(row.useEndDate);//row 表示一行数据, updateTime 表示要格式化的字段名称
      var year=t.getFullYear(),
              month=t.getMonth()+1,
              day=t.getDate(),
              hour=t.getHours(),
              min=t.getMinutes(),
              sec=t.getSeconds();
      var newTime=year+'-'+
              (month<10?'0'+month:month)+'-'+
              (day<10?'0'+day:day)+" "+
              (hour<10?'0'+hour:hour)+':'+
              (min<10?'0'+min:min)+':'+
              (sec<10?'0'+sec:sec);
      return newTime;
      },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>

<template>
  <el-dialog title="修改密码" :visible.sync="visible" @close="closeDialog" class="table" width="35%">

    <el-form class="login-form level" ref="loginForm" :model="form" :rules="rules">      
      <el-form-item prop="password">
        <p class="label">
          <i class="iconfont iconmima1"></i>
          <label>密码</label>
        </p>
        <el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
      </el-form-item>
      <el-form-item prop="newpwd">
        <p class="label">
          <i class="iconfont iconmima1"></i>
          <label>新密码</label>
        </p>
        <el-input v-model="form.newPwd" type="password" placeholder="请输入新密码"></el-input>
      </el-form-item>
      <el-form-item prop="newpwd2">
        <p class="label">
          <i class="iconfont iconmima1"></i>
          <label>确认密码</label>
        </p>
        <el-input v-model="form.newPwdConfirm" type="password" placeholder="请再次输入新密码"></el-input>
      </el-form-item>
        <el-form-item class="btnCenter">
          <a class="btn-black btnCenter" @click="findPassword">修改密码</a>
        </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
  import regexJs from "../../../utils/regex";
  import { updatePwd, getVerificationCode, checkCaptcha } from "../../../api/auth";
  import SIdentify from '../../../components/identify/identify';
  import { message } from '@/utils/resetMessage';
export default {
  props: ["dialogVisible"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
      },
      deep: true
    }
  },
  components:{
    SIdentify
  },
  computed: {
    updateSendCodeCountState() {
      return this.$store.state.common.sendCodeCount;
    }

  },
  data() {
    return {
      visible: false,
      form: {
        password: "",
        newPwd:"",
        newPwdConfirm: ""
      },
      src: window.baseUrl+'/api/auth/captcha.jpg',
      rules: {
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        newPwd: [ { required: true, message: "请输入新密码", trigger: "blur"}],
        newPwdConfirm: [ { required: true, message: "请输入确认密码", trigger: "blur"}]
      }
    };
  },
  mounted() {
    
  },
  created() {
    
  },
  methods: {
    findPassword() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          if (
              !(
                  regexJs.telephone.test(this.form.userName) ||
                  regexJs.email.test(this.form.userName)
              )
          ) {
            return false;
          }
          if (this.form.newPwd != this.form.newPwdConfirm) {
            // todo: 密码不一致提示
            message.error({
              message: '两次密码不一致',
              duration: 2000,
              offset: 80
            })
            return false;
          }
          this.judgeUserType();
          updatePwd(this.form).then(result => {
            if (result) {
              message.success({
                message: '修改密码成功',
                duration: 2000,
                offset: 80
              })
              this.$router.push({ path: "/login" });
            }
          }).catch(function(errorResult) {
            message.error({
              message: errorResult,
              duration: 2000,
              offset: 80
            })
            console.log("errorResult", errorResult);
          });
        } else {
          return false;
        }
      });
    },
    judgeUserType() {
      this.form.type = regexJs.email.test(this.form.userName)
              ? constants.loginNameType.email
              : constants.loginNameType.phone;
    },
    refreshCode: function () {
      this.src = window.baseUrl+"/api/auth/captcha.jpg?t=" + new Date().getTime();
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
    sendCode() {
      if (
              !this.form.userName ||!this.form.captcha||
              !(
                      regexJs.telephone.test(this.form.userName) ||
                      regexJs.email.test(this.form.userName)
              )
      ) {
        message.error({
          message: '用户名不正确',
          duration: 2000,
          offset: 80
        })
        return;
      }
      this.judgeUserType();

      let captchaparam = {
        captcha: this.form.captcha
      };
      checkCaptcha(captchaparam).then(result => {
        //验证码正确才发送短信
        let param = {
          user: this.form.userName,
          type: this.form.type,
          sendCodeType:constants.sendCodeType.reset,
        };
        getVerificationCode(param)
                .then(result => {
                  this.isSendCode = true;
                  this.timeInterval = setInterval(() => {
                    if (this.count <= 0) {
                      clearInterval(this.timeInterval);
                      this.isSendCode = false;
                      this.count=120;
                      this.$store.commit("updateSendCodeCountState",this.count);
                    } else {
                      this.count--;
                      this.$store.commit("updateSendCodeCountState",this.count) /* 提交vuex状态改变 */
                    }
                  }, 1000);
                })
                .catch(errorResult => {
                  this.isSendCode = false;
                  message.error({
                    message: errorResult,
                    duration: 2000,
                    offset: 80
                  })
                });
              })
              .catch(errorResult => {
                //验证码错误，刷新
                message.error({
                  message: errorResult,
                  duration: 2000,
                  offset: 80
                })
                this.refreshCode();

              });


    },
    goBack() {
      this.$router.push({ path: "/login" });
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  }
};
</script>

<style lang="scss" scoped>
.login {
  padding-top: 20px;
  .tit {
    margin-bottom: 50px;
    overflow: hidden;
    h3 {
      float: left;
      font-size: 24px;
      color: #000;
    }
    .wei {
      float: right;
      line-height: 30px;
      color: #7e7e7e;
      font-size: 14px;
      cursor: pointer;
      .iconfont {
        margin-right: 10px;
        color: #5acd54;
        font-size: 16px;
      }
    }
  }
}
.btnCenter{
  display:block;
  margin:0 auto
}
</style>

/**
 * Created by Administrator on 2019/10/7.
 */
import { request } from '../utils/request'
import serviceInterface from '../api/serviceInterface'
/**
 * 用户优惠券列表,会更新数据库
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const List = (params) => {
    return request({
        url: serviceInterface.coupon.list,
        params: params
    })
}

/**
 * 用户优惠券列表，查可用的
 * @param params
 * @returns {*|Promise<unknown>|undefined}
 */
export const userCouponList = (params) => {
    return request({
        url: serviceInterface.coupon.userCouponlist,
        params: params
    })
}
